import React from 'react'
import ReactDOM from 'react-dom/client'

/* CSS */
import './design/css/app.css'
import './design/css/preloader.css'
import './design/css/play.css'
import './design/css/bet.css'
import './design/css/buttons.css'
import './design/css/inactiontiming.css'
import './design/css/player-card.css'
import './design/css/player.css'
import './design/css/additional.css'
import './design/css/dealer.css'

import App from './App'
import reportWebVitals from './reportWebVitals'

import TestBet from './TestBet'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<App />)

reportWebVitals()